import PropTypes from 'prop-types';
import React from 'react';

export default class BulletSection extends React.Component {
	render() {
		const bulletListOne = this.props.listOne.map((info) => (
			<li className="mb-8 last:mb-0">
				<h4 className="font-semibold mb-4 text-primary">
					{info.title}
				</h4>
				<p className="text-sm">{info.content}</p>
			</li>
		));

		const bulletListTwo = this.props.listTwo.map((info) => (
			<li className="mb-8 last:mb-0">
				<h4 className="font-semibold mb-4 text-primary">
					{info.title}
				</h4>
				<p className="text-sm">{info.content}</p>
			</li>
		));

		return (
			<section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 max-w-7xl mx-auto px-8 lg:px-16 py-12">
				<header className="md:col-span-2 lg:col-span-1">
					<h3 className="font-semibold mb-8 text-3xl">
						{this.props.title}
					</h3>
					<p>{this.props.content}</p>
				</header>
				<ul>{bulletListOne}</ul>
				<ul>{bulletListTwo}</ul>
			</section>
		);
	}
}

BulletSection.propTypes = {
	content: PropTypes.string,
	title: PropTypes.string,
	listOne: PropTypes.array,
	listTwo: PropTypes.array,
};
