import PropTypes from 'prop-types';
import React from 'react';

export default class Video extends React.Component {
	render() {
		return (
			<div className="flex items-center justify-center">
				<iframe
					allow="accelerometer; clipboard-write; encrypted-media; picture-in-picture"
					allowFullScreen
					frameBorder="0"
					height={this.props.height}
					src={this.props.src}
					title={this.props.title}
					width={this.props.width}
				>
				</iframe>
			</div>
		);
	}
}

Video.defaultProps = {
	height: '360',
	title: 'YouTube video player',
	width: '640'
}

Video.propTypes = {
	height: PropTypes.string,
	src: PropTypes.string.isRequired,
	title: PropTypes.string,
	width: PropTypes.string
};
