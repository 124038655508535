import PropTypes from 'prop-types';
import React from 'react';

export default class MajorSection extends React.Component {
	render() {
		return (
			<section className={`max-w-7xl mx-auto px-8 lg:px-16 py-24`}>
				{this.props.children}
			</section>
		);
	}
}

MajorSection.defaultProps = {
	maxWidth: "7xl",
}

MajorSection.propTypes = {
	children: PropTypes.node.isRequired,
	maxWidth: PropTypes.string,
}