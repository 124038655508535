import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import BulletSection from 'components/shared/BulletSection';
import content from 'content/content.json';
import Hero from 'components/shared/Hero';
import imageList from '../config/images';
import Layout from 'components/Layout';
import MajorSection from 'components/shared/MajorSection';
import paths from '../config/paths';
import React from 'react';
import seo from 'config/seo.json';
import Video from 'components/shared/Video';
import videoList from '../config/videos';

export default class Homeowners extends React.Component {
	render() {
		return (
			<Layout
				title={seo.homeowners.title}
				description={seo.homeowners.description}
			>
				<Hero
					alt={imageList.homeowners.alt}
					buttonText={content.pages.homeowners.hero.button}
					buttonUrl={paths.register.homeowner}
					content={content.pages.homeowners.hero.content}
					image={getImage(this.props.data.file)}
					isExternalLink={true}
					paddingBottom="8"
					title={content.pages.homeowners.hero.title}
				/>
				<BulletSection
					title={content.pages.homeowners.about.title}
					content={content.pages.homeowners.about.content}
					listOne={content.pages.homeowners.about.infoListOne}
					listTwo={content.pages.homeowners.about.infoListTwo}
				/>
				<MajorSection>
					<Video
						height="576"
						src={videoList.homeowner}
						width="1024"
					/>
				</MajorSection>
			</Layout>
		);
	}
}

export const pageQuery = graphql`
	{
		file(relativePath: { eq: "hero-homeowners.png" }) {
			id
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
